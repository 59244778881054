export const environment = {
	production: true,
	auth: {
		clientId: '21eb69e5-daf2-4933-9e9e-b31588d0f921',
		authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
		audience: 'api://21eb69e5-daf2-4933-9e9e-b31588d0f921/',
		scope: 'access_as_user',
		redirectUri: location.origin,
	},
	backEndApiUrl: 'https://tco-api-qa.azurewebsites.net',
	connectionString:
		'InstrumentationKey=7aa573b4-12b1-4267-8e3d-65655123679c;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
};
