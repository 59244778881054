import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RtNotification, RtNotificationType } from '../../models/rt-notification.model';

/**
 * Component in charge of :
 * - rendering an actual notification
 */
@Component({
  selector: 'rt-notification',
  templateUrl: './rt-notification.component.html',
  styleUrls: ['./rt-notification.component.scss'],
})
export class RtNotificationComponent implements OnInit {
  /**
   * Notification object
   */
  @Input() notification!: RtNotification;

  /**
   * Used to change the style of the notification
   */
  typeNgClass!: string;
  /**
   * Used to change the icon in the notification
   */
  iconNgClass!: string;

  /**
   * Event emitted when the user is clicking on the 'x' button
   */
  @Output() closing: EventEmitter<RtNotification> = new EventEmitter();

  /**
   * On init :
   * - Depending on type of notification, associating related CSS classes
   */
  ngOnInit(): void {
    switch (this.notification.type) {
      case RtNotificationType.Error:
        this.typeNgClass = 'notification-error';
        this.iconNgClass = 'zmdi zmdi-alert-triangle';
        break;
      case RtNotificationType.Warning:
        this.typeNgClass = 'notification-warning';
        this.iconNgClass = 'zmdi zmdi-alert-circle';
        break;
      case RtNotificationType.Info:
        this.typeNgClass = 'notification-info';
        this.iconNgClass = 'zmdi zmdi-info';
        break;
      case RtNotificationType.Success:
        this.typeNgClass = 'notification-success';
        this.iconNgClass = 'zmdi zmdi-check-circle';
        break;
      default:
        throw new Error('Notification type not recognized !');
    }
  }

  /**
   * Method used to send the event indicating the user wants to close the notification
   * @param notification the notification that needs to be closed
   */
  closingNotification(notification: RtNotification): void {
    this.closing.emit(notification);
  }
}
