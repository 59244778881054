import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppComponent } from './app.component';

const ROUTES: Routes = [
	{
		path: '',
		component: AppComponent,
		loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
		canActivate: [MsalGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(ROUTES)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
