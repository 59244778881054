import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { DatePipe, LocationStrategy, PathLocationStrategy, registerLocaleData, ViewportScroller } from '@angular/common';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgIconsModule } from '@ng-icons/core';
import { simpleMicrosoftexcel } from '@ng-icons/simple-icons';
import { RtButtonModule, RtHeadingModule, RtLinkModule } from './layout/components/rt-design-system/src/public-api';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
/**
 * Loads the i18n's translation into the application.
 *
 * @param http
 * @returns
 */
export function i18nLoadFiles(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		SharedModule,
		AppRoutingModule,
		HttpClientModule,
		ToastrModule.forRoot(), // ToastrModule adde
		NgIconsModule.withIcons({ simpleMicrosoftexcel }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: i18nLoadFiles,
				deps: [HttpClient],
			},
		}),
		BrowserAnimationsModule,
		RtLinkModule,
		RtButtonModule,
		RtHeadingModule,
		MsalModule.forRoot(
			new PublicClientApplication({
				auth: environment.auth,
				cache: {
					cacheLocation: 'localStorage',
					storeAuthStateInCookie: false,
				},
			}),
			{
				interactionType: InteractionType.Redirect, // MSAL Guard Configuration
				authRequest: {
					scopes: ['user.read'],
				},
			},
			{
				interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
				protectedResourceMap: new Map([
					[environment.backEndApiUrl, [environment.auth.audience + environment.auth.scope]],
					['https://graph.microsoft.com/v1.0/me', ['user.read']],
				]),
			}
		),
		NgbModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		MsalGuard,
		DatePipe,
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
	constructor(router: Router, viewportScroller: ViewportScroller) {
		viewportScroller.setOffset([0, 250]);
		router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((event) => {
			const e = event as Scroll;
			if (e.anchor) {
				// anchor navigation
				setTimeout(() => {
					viewportScroller.scrollToAnchor(e.anchor!);
				});
			} else if (e.position) {
				// backward navigation
				viewportScroller.scrollToPosition(e.position);
			} else {
				// forward navigation
				viewportScroller.scrollToPosition([0, 0]);
			}
		});
	}
}
