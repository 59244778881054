import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtNotificationsContainerComponent } from './components/rt-notifications-container/rt-notifications-container.component';
import { RtNotificationComponent } from './components/rt-notification/rt-notification.component';

/**
 * RT notification module
 */
@NgModule({
  declarations: [RtNotificationsContainerComponent, RtNotificationComponent],
  imports: [CommonModule],
  exports: [RtNotificationsContainerComponent, RtNotificationComponent],
})
export class RtNotificationModule {}
