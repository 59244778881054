import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { UserService } from './_services/user.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	constructor(private userService: UserService, private router: Router) {}

	ngOnInit(): void {
		this.initAppInsights();
		this.userService.loadUser();
		this.router.navigate(['']);
	}

	public initAppInsights(): void {
		const appInsights = new ApplicationInsights({
			config: {
				connectionString: environment.connectionString,
			},
		});

		this.userService.getRtUser().subscribe((data) => {
			appInsights.loadAppInsights();
			appInsights.setAuthenticatedUserContext(data.id);
			appInsights.trackPageView();
		});
	}
}
