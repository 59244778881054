import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RtNotification } from '../models/rt-notification.model';

/**
 * Service providing a way to display a notification in the application
 */
@Injectable({
  providedIn: 'root',
})
export class RtNotificationService {
  /**
   * The notification observable
   */
  notification$: Subject<RtNotification> = new Subject();

  /**
   * Method used to subscribe to the notification observable
   * @returns the notification observable
   */
  listenToNotification(): Observable<RtNotification> {
    return this.notification$.asObservable();
  }

  /**
   * Main method to display a notification
   * @param notification the notification object to display
   */
  notify(notification: RtNotification): void {
    this.notification$.next(notification);
  }
}
