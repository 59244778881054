/**
 * Class representing a notification that will be displayed to the user
 */
export class RtNotification {
  /**
   * Unique id of a notification
   */
  id!: string;
  /**
   * Type (Enum) of a notification
   */
  type!: RtNotificationType;
  /**
   * Content of the notification
   */
  message!: string;
  /**
   * Indicates if a notification can be closed
   */
  isCloseable = false;

  /**
   * Default constructor
   */
  constructor(init?: Partial<RtNotification>) {
    Object.assign(this, init);
  }
}

/**
 *  Enumeration representing the type of a notification (Error, Warning, Info, Success)
 */
export enum RtNotificationType {
  Error,
  Warning,
  Info,
  Success,
}
