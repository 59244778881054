<div [id]="notification.id" class="notification" [ngClass]="typeNgClass">
  <button
    *ngIf="notification.isCloseable"
    class="notification-close"
    type="button"
    (click)="closingNotification(this.notification)"
  >
    <span class="zmdi zmdi-close"></span>
  </button>
  <span class="notification-icon">
    <span [ngClass]="iconNgClass"></span>
  </span>
  <span class="content" [innerHtml]="notification.message"></span>
</div>
