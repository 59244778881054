import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SearchPipe } from './pipes/search.pipe';
import { AlertComponent } from './component/alert/alert.component';
import { NgIconsModule } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';
import { SelectCountriesComponent } from './component/select-countries/select-countries.component';
import { SelectStringComponent } from './component/select-string/select-string.component';
import { SelectComponent } from './component/select/select.component';
import { StepperComponent } from './component/stepper/stepper.component';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OnlyDigitDirective } from './directives/only-digit.directive';

@NgModule({
	declarations: [
		SelectCountriesComponent,
		SelectComponent,
		SelectStringComponent,
		StepperComponent,
		ClickOutsideDirective,
		SearchPipe,
		AlertComponent,
		OnlyDigitDirective,
	],
	imports: [NgIconsModule.withIcons({ heroXMark }), CommonModule, TranslateModule, NgxSpinnerModule, RouterModule],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule,
		SelectCountriesComponent,
		SelectComponent,
		SelectStringComponent,
		StepperComponent,
		NgxChartsModule,
		NgxSpinnerModule,
		ClickOutsideDirective,
		SearchPipe,
		AlertComponent,
		OnlyDigitDirective,
	],
})
export class SharedModule {}
