import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RtBreadcrumbsComponent } from './components/rt-breadcrumbs/rt-breadcrumbs.component';
import { RtHeaderComponent } from './components/rt-header/rt-header.component';
import { RtMenuItemComponent } from './components/rt-menu-item/rt-menu-item.component';
import { RtSidebarComponent } from './components/rt-sidebar/rt-sidebar.component';
import { RtSubMenuItemComponent } from './components/rt-sub-menu-item/rt-sub-menu-item.component';
import { RtSubSubMenuItemComponent } from './components/rt-sub-sub-menu-item/rt-sub-sub-menu-item.component';
import {
  RtAccordionDirective,
  RtAccordionToggleDirective,
  RtAccordionContentDirective,
} from './directives/rt-accordion.directive';

@NgModule({
  declarations: [
    RtHeaderComponent,
    RtBreadcrumbsComponent,
    RtSidebarComponent,
    RtAccordionDirective,
    RtAccordionToggleDirective,
    RtAccordionContentDirective,
    RtMenuItemComponent,
    RtSubMenuItemComponent,
    RtSubSubMenuItemComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    RtHeaderComponent,
    RtSidebarComponent,
    RtMenuItemComponent,
    RtSubMenuItemComponent,
    RtSubSubMenuItemComponent,
  ],
})
export class RtLayoutModule {}
