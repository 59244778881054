import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtButtonDirective } from './directives/rt-button.directive';

@NgModule({
  declarations: [RtButtonDirective],
  imports: [CommonModule],
  exports: [RtButtonDirective],
})
export class RtButtonModule {}
