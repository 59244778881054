import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RtNotification } from '../../models/rt-notification.model';
import { RtNotificationService } from '../../services/rt-notification.service';

/**
 * Component responsible of rendering notifications created thru
 * the notifications service and removing notifications that the
 * user wants to be hidden.
 */
@Component({
  selector: 'rt-notifications-container',
  templateUrl: './rt-notifications-container.component.html',
  styleUrls: ['./rt-notifications-container.component.scss'],
})
export class RtNotificationsContainerComponent implements OnInit, OnDestroy {
  /**
   * The list of notifications
   */
  notifications: Array<RtNotification> = [];

  /**
   * Subject used to avoid memory leaks on component destruction
   */
  destroy$ = new Subject<void>();

  /**
   * Default constructor
   * @param notificationService used to handle notifications
   * @param router used to react to route changes (delete notifications)
   */
  constructor(private notificationService: RtNotificationService, private router: Router) {}

  /**
   * On init :
   * - Listen to notifications coming through the service
   * - Listen to route changes to clear notifications
   */
  ngOnInit(): void {
    this.notificationService
      .listenToNotification()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notification: RtNotification) => {
        this.notifications.push(notification);
      });
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => {
        this.notifications = [];
      });
  }

  /**
   * Method used to delete a notification
   * @param notification the notification to delete
   */
  clearNotification(notification: RtNotification): void {
    this.notifications = this.notifications.filter((x) => x !== notification);
  }

  /**
   * on destroy, we release all subscriptions
   */
  ngOnDestroy(): void {
	  this.destroy$.next();
	  this.destroy$.complete();
  }
}
