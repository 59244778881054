/**
 * Class representing the current user signed in to display in the header
 */
export class RtCurrentUser {
	id!: string;
	firstName!: string;
	lastName!: string;
	oneLineInfo?: string;

	constructor(init?: Partial<RtCurrentUser>) {
		Object.assign(this, init);
	}
}
