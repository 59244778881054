import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RtCurrentUser } from '../layout/components/rt-design-system/src/public-api';

@Injectable({
	providedIn: 'root',
})
export class UserService  {
	private static MICROSOFT_GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

	private userBehaviorSubject: BehaviorSubject<RtCurrentUser> = new BehaviorSubject<RtCurrentUser>(new RtCurrentUser());
	public userObservable: Observable<RtCurrentUser> = this.userBehaviorSubject.asObservable();

	destroy$ = new Subject<any>();

	constructor(private msalBroadcastService: MsalBroadcastService, private httpClient: HttpClient) {}

	loadUser(): void {
		this.msalBroadcastService.inProgress$
			.pipe(
				takeUntil(this.destroy$),
				filter((status: InteractionStatus) => status === InteractionStatus.None)
			)
			.subscribe(() => {
				this.getRtUser().subscribe((aadUser: any) => {
					this.userBehaviorSubject.next(
						new RtCurrentUser({
							id: aadUser.id,
							firstName: aadUser.givenName,
							lastName: aadUser.surname,
							oneLineInfo: aadUser.jobTitle,
						})
					);
				});
			});
	}

	public getRtUser(): Observable<any> {
		return this.httpClient.get(UserService.MICROSOFT_GRAPH_ENDPOINT).pipe(takeUntil(this.destroy$));
	}
}
